<template>
  <Card class="form-add-assistant">
    <template slot="header">
      <h3>{{ $t('setting.bu.assistant.addAssistant') }}</h3>
    </template>
    <template slot="body">
      <div class="add-assistant">
        <div class="option-bu">
          <label>{{ $t('setting.bu.assistant.BuName') }}</label>
          <select v-model="selectBu">
            <option v-for="option in optionsBu" :key="option.promoBuLabel" :value="option">
              {{ option.promoBuLabel }}
            </option>
          </select>
        </div>

        <autocomplete-email
          :disabled="userSearchDisabled"
          :isLoading="userSearchDisabled"
          :current-search="userSearch"
          @key-pressed="debounceAssistant"
          @selected="setAssistant"
          :submit-error="selectAssistant?.mail ? '' : $t('error.error')"
          :placeholder="$t('setting.bu.assistant.searchMail')"
          :items="userAssistant"
          @is-correct="isForm.isEmail = $event"
        />

        <v-button
          :disabled="!selectBu || !isAssistant"
          class="add-button"
          :label="$t('setting.bu.assistant.add')"
          icon="record"
          color="pink"
          @action="$emit('save', getForm())"
        />
      </div>
    </template>
  </Card>
</template>

<script>
  import VSvg from '@/components/base/Svg'
  import Card from '@/components/Card.vue'
  import VButton from '@/components/Button'
  import AutocompleteEmail from '../../AutocompleteEmail.vue'
  import { extendWithAuthorization, http } from '@/http'
  import { mapGetters, mapState } from 'vuex'

  const MAX_LENGTH = 3

  export default {
    name: 'FormAddAssistant',
    components: {
      VSvg,
      Card,
      VButton,
      AutocompleteEmail
    },
    data() {
      return {
        userSearchDisabled: false,
        userSearch: '',
        userAssistant: [],
        isAssistant: false,
        optionsBu: [],
        selectBu: null,
        selectAssistant: null
      }
    },
    computed: {
      ...mapState('user', ['foundUsers']),
      ...mapGetters('user', ['searchUsers'])
    },
    methods: {
      async debounceAssistant(val) {
        this.isAssistant = false
        this.userSearch = val
        clearTimeout(this.timoutID)
        if (val !== '' && val.length > MAX_LENGTH) {
          this.timoutID = setTimeout(async () => {
            this.userSearchDisabled = true
            this.$store.dispatch('user/searchUsers', { mails: this.userSearch }).then(() => {
              this.userAssistant = this.foundUsers
              this.userSearchDisabled = false
            })
          }, 1000)
        } else if (val === '') {
          this.setAssistant(null)
        }
      },
      setAssistant(value) {
        this.selectAssistant = value
        this.userSearch = !value ? '' : value.mail
        if (value) this.isAssistant = true
      },
      getForm() {
        const form = {
          promoBuType: this.selectBu.promoBuType,
          promoBuLabel: this.selectBu.promoBuLabel,
          promoBuEmail: this.selectBu.promoBuEmail,
          assistant: this.selectAssistant.displayName,
          assistantEmail: this.selectAssistant.mail,
          assistantNumber: this.selectAssistant.uid
        }
        this.selectBu = null
        this.selectAssistant = null
        this.userSearch = ''
        return form
      }
    },
    async created() {
      const extendedHttp = await extendWithAuthorization(http)
      this.optionsBu = await extendedHttp.get(`setting/bu`).json()
    }
  }
</script>

<style scoped lang="scss">
  .form-add-assistant {
    .add-assistant {
      display: flex;
      align-items: center;
    }
    .option-bu {
      display: flex;
      flex-direction: column;
      font-weight: bold;
      min-width: 20rem;
      margin-right: 10px;
      label {
        padding-bottom: 5px;
      }
      select {
        height: 26px;
      }
    }
    .add-button {
      margin-left: 10px;
    }
  }
</style>
