<template>
  <div class="main-container">
    <h1>{{ $t('setting.bu.assistant.title') }} </h1>
    <div class="menu-param">
      <ElemPicker :elements="elements" :selected-element="$t('rules.assistant')" color="blue" @add-query="onChange" />
    </div>
    <div class="content-bu">
      <form-add-assistant class="add-assistant" @save="addAssistant" />
      <ag-grid-vue
        id="myGrid"
        class="ag-theme-alpine"
        :grid-options="gridOptions"
        :column-defs="columnDefs"
        :default-col-def="defaultColDef"
        :row-data="rowData"
        @grid-ready="onGridReady"
      />
    </div>
  </div>
</template>

<script>
  import 'ag-grid-community/styles/ag-grid.css'
  import 'ag-grid-community/styles/ag-theme-alpine.css'
  import { AgGridVue } from 'ag-grid-vue'
  import ActionDeleteRenderer from '@/components/setting/assistant/grid/ActionDeleteRenderer.vue'
  import FormAddAssistant from '@/components/setting/assistant/FormAddAssistant.vue'
  import VSvg from '@/components/base/Svg'
  import { extendWithAuthorization, http } from '@/http'
  import { sendErrorToast, sendSuccessToast } from '@/utils/utils'
  import ElemPicker from '@/components/ElemPicker.vue'

  export default {
    name: 'SettingBuAssistant',
    components: {
      AgGridVue,
      VSvg,
      ActionDeleteRenderer,
      FormAddAssistant,
      ElemPicker
    },
    data() {
      return {
        gridOptions: null,
        gridApi: null,
        columnApi: null,
        columnDefs: null,
        defaultColDef: null,
        rowData: null,
        elements: [
          { name: this.$t('rules.settingAssistant'), value: this.$t('rules.assistant') },
          { name: this.$t('rules.settingMarket'), value: this.$t('rules.market') }
        ]
      }
    },
    beforeMount() {
      this.gridOptions = { defaultColDef: { resizable: true, sortable: true }, rowHeight: 30, headerHeight: 30 }
      this.columnDefs = [
        { field: 'promoBuLabel', headerName: this.$t('setting.bu.assistant.promoBuLabel') },
        { field: 'assistantEmail', headerName: this.$t('setting.bu.assistant.assistantEmail'), sort: 'asc' },
        {
          headerName: '',
          width: 70,
          cellRenderer: 'ActionDeleteRenderer',
          editable: false,
          colId: 'action'
        }
      ]
      this.defaultColDef = {
        editable: false,
        resizable: true
      }
    },
    mounted() {
      this.gridApi = this.gridOptions.api
      this.gridColumnApi = this.gridOptions.columnApi
    },
    methods: {
      async addAssistant(e) {
        this.$store.commit('common/SET_LOADING', true)
        try {
          const extendedHttp = await extendWithAuthorization(http)
          await extendedHttp.post(`setting/bu/assistant`, { searchParams: e }).json()
          this.rowData = [...this.rowData, e]
          sendSuccessToast(this.$t('setting.bu.assistant.message.addAssistant'))
        } catch (err) {
          console.log(err)
          sendErrorToast(this.$t('setting.bu.assistant.message.errorAddAssistant'))
        }
        this.$store.commit('common/SET_LOADING', false)
      },

      async onGridReady(params) {
        params.api.sizeColumnsToFit()
        try {
          const extendedHttp = await extendWithAuthorization(http)
          this.rowData = await extendedHttp.get(`setting/bu/assistant`).json()
        } catch (err) {
          this.rowData = null
          sendErrorToast(this.$t('setting.bu.assistant.message.errorAssistant'))
        }
      },
      onChange() {
        this.$router.push({
          name: 'SettingBuMarket'
        })
      }
    }
  }
</script>
<style scoped lang="scss">
  .menu-param {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 1em 0;
    .elems-container {
      width: 750px;
    }
  }
  .content-bu {
    display: flex;
    flex-direction: column;
    align-items: center;
    .add-assistant {
      margin-bottom: 10px;
    }
    .ag-theme-alpine {
      height: 500px;
      width: 800px;
    }
  }
</style>
