<template>
  <v-svg @click="deleteAssistant" class="cursor-pointer" name="trash" size="100%" />
</template>

<script>
  import VSvg from '@/components/base/Svg'
  import { extendWithAuthorization, http } from '@/http'
  import { sendErrorToast } from '@/utils/utils'
  export default {
    name: 'ActionDeleteRenderer',
    components: {
      VSvg
    },
    methods: {
      async deleteAssistant() {
        this.$store.commit('common/SET_LOADING', true)
        try {
          const extendedHttp = await extendWithAuthorization(http)
          await extendedHttp.delete(`setting/bu/${this.params.data.promoBuType}/assistant/${this.params.data.assistantNumber}`)
          this.params.api.applyTransaction({
            remove: [this.params.node.data]
          })
        } catch (err) {
          console.log(err)
          sendErrorToast(this.$t('setting.bu.assistant.message.errorDelete'))
        }
        this.$store.commit('common/SET_LOADING', false)
      }
    }
  }
</script>

<style scoped>
  .v-svg {
    display: flex;
    height: 100%;
    padding: 4px;
    justify-content: center;
    vertical-align: middle;
  }
</style>
